<template>
  <v-app>
    <top-bar 
      :brands="user.brands"
      :roles="user.roles"
      :user="user"
      :admin="admin"
    />

    <page-view 
      :admin="admin"
    />

    <v-overlay
      :value="updating"
      opacity=".8"
      class="text-center"
    >
      <v-progress-circular 
        indeterminate 
        size="64" 
        color="primary"
        z-index="10"
      />
      <v-subheader>
        Verificando updates...
      </v-subheader>
    </v-overlay>

    <toast />
  </v-app>
</template>

<script>
  // Utilities
  import services from '@/services'
  import { sync } from 'vuex-pathify'

  export default {
    name: 'LayoutHome',

    props: {
      updating: {
        type: Boolean,
        default: false,
      },
      admin: {
        type: Boolean,
        default: false,
      }
    },

    components: {
      TopBar: () => import('./AppBar'),
      PageView: () => import('./View'),
      Toast: () => import('@/components/Toast'),
    },

    computed: {
      user: sync('user/data'),
      // toast: sync('app/toast'),
    },

    methods: {
      ...services,

      onReset () {
        this.$emit('reset');
      }
    },

    beforeDestroy () {
    },

    mounted () {
    }
  }
</script>
